
<template>
  
  <section class="login-2">
    <pageLoader v-if="ploader" :loadingText="'En cours de chargement'"/>
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-stretch auth auth-img-bg">
          <div class="row flex-grow">
            <div class="col-lg-6 d-flex align-items-center justify-content-center">
              <div class="auth-form-transparent text-left p-3">
                <div class="brand-logo">
                  <img src="../../assets/images/logo-ailem.png" alt="logo">
                </div>

                <h4>Bienvenue!</h4>
                <h6 class="font-weight-light">Content de vous revoir!</h6>
                <!-- début form -->
                <form class="pt-3" @submit.prevent="handleSubmit">
                  <b-alert show variant="warning" v-if="error"><a href="javascript:void(0);" class="alert-link">Mot de passe ou identifiant incorrect. Veuillez réessayer.</a></b-alert>

                  <div class="form-group">
                    <label for="exampleInputEmail">Nom d'utilisateur</label>
                    <div class="input-group">
                      <div class="input-group-prepend bg-transparent">
                        <span class="input-group-text bg-transparent border-right-0">
                          <i class="mdi mdi-account-outline text-primary"></i>
                        </span>
                      </div>
                      <input type="text" class="form-control form-control-lg border-left-0" id="userName" v-model="userName" placeholder="Nom d'utilisateur" required>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="exampleInputPassword">Mot de passe</label>
                    <div class="input-group">
                      <div class="input-group-prepend bg-transparent">
                        <span class="input-group-text bg-transparent border-right-0">
                          <i class="mdi mdi-lock-outline text-primary"></i>
                        </span>
                      </div>
                      <input type="password" class="form-control form-control-lg border-left-0" id="password" v-model="password" placeholder="Mot de passe" required>
                    </div>
                  </div>
                  <div class="my-2 d-flex justify-content-between align-items-center">
                    <div class="form-check">
                      <label class="form-check-label text-muted">
                        <input type="checkbox" class="form-check-input"><i class="input-helper"></i>
                        Rester connecté
                      </label>
                    </div>
                    <router-link to="/lock-screen">
                    <a href="javascript:void(0);" class="auth-link text-info">Mot de passe oublié?</a>
                    </router-link>
                  </div>
                  <div class="my-3">
                      <button class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" >SE CONNECTER <span v-show="loadingBar" class="circle-loader ml-2"></span></button>
                  </div>
                  <div class="mb-2 d-flex">
                    <button type="button" class="btn btn-facebook auth-form-btn flex-grow mr-1">
                      <i class="mdi mdi-facebook mr-2"></i>Facebook </button>
                    <button type="button" class="btn btn-google auth-form-btn flex-grow ml-1">
                      <i class="mdi mdi-google mr-2"></i>Google </button>
                  </div>
                  <div class="text-center mt-4 font-weight-light"> Vous n'avez pas de compte?
                    <router-link to="/inscription" class="text-primary">Créer</router-link>
                    <router-link to="/edit-text" class="text-primary">edit-text</router-link>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-6 login-half-bg d-flex flex-row">
              <p class="text-white font-weight-medium text-center flex-grow align-self-end">Copyright &copy; 2019 All rights reserved.</p>
            </div>
          </div>
        </div>
        <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>

</template>

<script src="https://unpkg.com/vue-cookies@1.7.4/vue-cookies.js"></script>
<script>
import router from '../../router/index'
import { mapGetters } from "vuex";
import pageLoader from '../../components/pageLoader.vue'
import axios from 'axios'

export default {
  name: 'authentification',
  components: {
      pageLoader
  },
  data () {
    return {
      userName:null,
      password: null,
      error:false,
      ploader:true,
      loadingBar:false
    }
  },
    computed: {
    ...mapGetters(["getAuth"]),
  },
  methods: {
    async handleSubmit(){
          const formData = {
              username:this.userName,
              password:this.password,
          }
          //console.log(this.$store)
          this.ploader =true
          await axios.post('api/login_check',formData)
            .then(
              resJWT => {
              localStorage.setItem('main-token', resJWT.data.token)
              }
            )
            .catch(
              errJWT => console.log(errJWT)
            )
          axios.post('login',formData)
            .then(
                reser => {
                            axios.get('myinfos',{headers:{
                              'X-AUTH-TOKEN':reser.data.token} })
                            .then(resGeter => {
                                //Enregistrer l'utilisateur dans authUser
                                this.$store.dispatch('login',{resmyinfo:resGeter ,reslogin:reser})
                            })
                            .catch(errGet => {
                              this.loadershow = false
                              console.log({errGet});
                                // this.$snotify.warning(errGet.response);

                            })
                        }
            )
            .catch(
                err => {
                  if(err.response){
                    this.ploader =false
                    this.error =true
                  }
                  if (err.message == "Network Error") {
                    axios.post('login',formData)
                      .then(
                            reser => {
                            axios.get('myinfos',{headers:{
                              'X-AUTH-TOKEN':reser.data.token} })
                            .then(resGeter => {
                                //Enregistrer l'utilisateur dans authUser
                                this.$store.dispatch('login',{resmyinfo:resGeter ,reslogin:reser})
                            })
                            .catch(errGet => {
                              this.loadershow = false
                              console.log({errGet});
                                // this.$snotify.warning(errGet.response);

                            })
                        }
                          
                      )
                      .catch(
                        errLog => {
                          this.loadershow = false
                          this.$snotify.warning("Network Error");
                          console.log(errLog);
                        }
                      )
                  }
                }
            ) 
      }
  },
  mounted(){
    this.$store.dispatch('AutoLogin')
    // console.log(this.getAuth)
    if(this.getAuth)
    {
      router.replace('/dashboard')
    }
    this.ploader = false
  }
  
}
</script>
<style scoped>
a:hover {
  color:inherit;
  text-decoration: none;
}
.auth-form-btn:active, .auth-form-btn:focus {
  background: linear-gradient(to right, #874fa2, #7540c3) !important
}
</style>